import {
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Divider,
} from "@mui/material";
// import styled from "@emotion/styled";
import React from "react";
import SectionDivider from "../components/SectionDivider";

const Solutions = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        // ml: "12.8rem",
        margin: 0,
        bgcolor: "primary.main",
        boxShadow: 0,

        flex: {
          mobile: "100%",
          tablet: "calc(50% - 20px)",
          desktop: "calc(25% - 20px)",
        },
      }}
    >
      <Card sx={{ bgcolor: "primary.main", boxShadow: 0 }}>
        <CardContent sx={{ boxShadow: 0 }}>
          <Typography
            variant="h1"
            sx={{
              mx: "0.8rem",
              mr: {
                desktop: "50%",
                tablet: "40%",
                mobile: "20%",
              },
              mb: "1.8rem",
              color: "primary.contrast",
              fontSize: {
                mobile: "2.2rem",
                tablet: "4rem",
                desktop: "4.5rem",
              },
            }}
            style={{ wordBreak: "true" }}
          >
            Solutions
          </Typography>
          <CardMedia
            component="img"
            image={require("../assets/images/solutions.png")}
            sx={{
              height: {
                mobile: "25vh",
                tablet: "40vh",
                desktop: "40vh",
              },
            }}
          />
          <Box sx={{ my: "0.8rem", display: "flex", flexWrap: "wrap" }} />
          <Typography
            variant="h6"
            sx={{
              ml: {
                desktop: "50%",
                tablet: "40%",
                mobile: "20%",
              },
            }}
            style={{ wordBreak: "true" }}
          >
            Our solutions are designed to be cost-effective and tailored to your
            business needs, providing valuable insights for decision-makers
            across a wide range of industries, including FMCG retail and
            manufacturing, real estate, SaaS, and the public sector.
          </Typography>
          <Box sx={{ my: "1.8rem", display: "flex", flexWrap: "wrap" }} />
          <SectionDivider />
          <Typography
            variant="h4"
            sx={{
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            Pricing & Revenue Management
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontStyle: "italic",
              color: "text.secondary",
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            FMCG Retail/CPG
          </Typography>
          <Typography
            variant="body1"
            sx={{
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            Our Price Monitoring solution establishes real-time connectivity
            between market data and your internal Financial Planning systems,
            enabling you to track the price levels of your key competitors and
            simulate the revenue and profit impacts of your pricing strategy.
          </Typography>
          <Box sx={{ my: "1.8rem", display: "flex", flexWrap: "wrap" }} />
          <Divider
            variant="middle"
            sx={{
              bgcolor: "text.secondary",
              // ml: "12.8rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
              my: "1.8rem",
            }}
          />{" "}
          <Typography
            variant="h4"
            sx={{
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            Promotion Evalution
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontStyle: "italic",
              color: "text.secondary",
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            FMCG Retail/CPG
          </Typography>
          <Typography
            variant="body1"
            sx={{
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            Our Promotion solution helps FMCG retailers and manufacturers
            evaluate past promotions by comparing actual performance to your
            sales, unit, and profit uplift targets. Utilising data and
            predictive models, our tool enables you to identify the key
            characteristics of successful promotions and make informed decisions
            about future promotion plans with confidence.
          </Typography>
          <Box sx={{ my: "1.8rem", display: "flex", flexWrap: "wrap" }} />
          <Divider
            variant="middle"
            sx={{
              bgcolor: "text.secondary",
              // ml: "12.8rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
              my: "1.8rem",
            }}
          />{" "}
          <Typography
            variant="h4"
            sx={{
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            Assortment Optimisation
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontStyle: "italic",
              color: "text.secondary",
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            FMCG Retail/CPG
          </Typography>
          <Typography
            variant="body1"
            sx={{
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            Our Assortment Optimisation tool empowers you to make informed
            decisions about your category and products at an exceptional level
            of detail. By combining market scanning, consumer panel, competitor,
            and retail customer data, it enables you to understand your market
            share and growth prospects through valuable customer intelligence.
            It provides answers to key questions such as: Are your products
            substitutable with those of your competitors? Is your growth coming
            from a new, switching, or existing customer base? And many more.
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default Solutions;
