import React from "react";
import { Paper, Typography, Box, Grid } from "@mui/material";

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const content = {
    brand: { image: "../assets/assets/images/logo_abr_dark.png", width: 110 },
    copy: `© 2022-${currentYear} All rights reserved.`,
  };
  return (
    <Paper
      sx={{
        position: "sticky",
        bottom: "1.2rem",
        left: 0,
        right: 0,
        bgcolor: "transparent",
        mx: "1.8rem",
        // px: "1.8rem",
        zIndex: "toolbar",
        // borderRadius: 0,
      }}
      elevation={0}
    >
      <Grid container direction="row">
        {/* <Grid item>
          <Box
            component="img"
            src="../assets/assets/images/logo_abr_dark.png"
          />
        </Grid> */}
        <Grid item>
          <Typography color="text.secondary" component="p" variant="caption">
            © 2022-{currentYear} empiricus analytica
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Footer;
