import { Typography, Box } from "@mui/material";
import React from "react";

const TextLogo = () => {
  return (
    <Box>
      <Typography
        variant="textLogo"
        sx={{
          fontWeight: "fontWeightBold",
          color: "primary.contrast",
          fontSize: {
            desktop: "1.75rem",
            tablet: "1.5rem",
            mobile: "1.2rem",
          },
        }}
      >
        empiricus
      </Typography>
      <Typography
        variant="textLogo"
        sx={{
          color: "text.secondary",
          pl: "0.5rem",
          fontWeight: "fontWeightLight",
          fontSize: {
            desktop: "1.75rem",
            tablet: "1.5rem",
            mobile: "1.2rem",
          },
        }}
      >
        analytica
      </Typography>
    </Box>
  );
};

export default TextLogo;
