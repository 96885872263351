import { Box, Button, Paper, Toolbar } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import React from "react";

const ContactButton = () => {
  return (
    <Box
      fullWidth
      sx={{
        my: "0.5rem",
        color: "primary.main",
      }}
    >
      <Button
        fullWidth
        variant="outlined"
        // startIcon={<SendIcon />}
        sx={{
          color: "primary.contrast",
          bgcolor: "primary.main",
          borderColor: "primary.contrast",
          fontSize: {
            mobile: "0.8rem",
            tablet: "1rem",
            desktop: "1.15rem",
          },
          fontWeight: {
            mobile: 650,
            tablet: 800,
            desktop: 800,
          },
          "&:hover": {
            color: "primary.main",
            bgcolor: "primary.contrast",
          },
        }}
        href="mailto:hello@empiricus-analytica.com"
      >
        Get in touch
      </Button>
    </Box>
  );
};

export default ContactButton;
