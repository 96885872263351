import "./index.css";
import { createTheme } from "@mui/material";
// import shadows, { Shadows } from '@mui/material/styles/shadows';

// customise colours
const justBlack = "#1f1f1f";
const charcoalGrey = "#404040";
const desireGrey = "#5f5f5f";
const vididWhite = "#fafafa";
const ashvilleGrey = "#bbbbbb";
const boldIrish = "#2a814a";

// customise fonts
const logoFont = "ABeeZee";
const bodyFont = "Noto Sans";

// override default theme
const Theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 820,
      desktop: 1240,
    },
  },
  palette: {
    common: {
      black: `${justBlack}`,
      white: `${vididWhite}`,
      green: `${boldIrish}`,
    },
    primary: {
      main: `${justBlack}`,
      contrast: `${vididWhite}`,
    },
    secondary: {
      main: `${charcoalGrey}`,
    },

    text: {
      primary: `${vididWhite}`,
      secondary: `${ashvilleGrey}`,
    },
    background: {
      default: `${justBlack}`,
      // elevated: `${icyGrey}`,
    },
  },
  typography: {
    allVariants: {
      fontFamily: `${logoFont}, ${bodyFont}, "Roboto","Helvetica","Arial", sans-serif`,
      color: `${vididWhite}`,
      textTransform: "none",
    },
    textLogo: {
      fontFamily: `${logoFont}`,
      fontSize: {
        desktop: "3.5rem",
        tablet: "1.75rem",
        mobile: "1rem",
      },
      fontWeightBold: 1000,
      fontWeightLight: 300,
    },
    body1: {
      fontFamily: `${bodyFont}, "Roboto","Helvetica","Arial", sans-serif`,
      // fontSize: "0.85rem",
      // fontWeightBold: 700,
      // fontWeightLight: 400,
      textTransform: "none",
      color: `${vididWhite}`,
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: `${bodyFont}, "Roboto","Helvetica","Arial", sans-serif`,
          "&.Mui-selected": {
            // bgcolor: `${vididWhite}`,
            color: `${vididWhite}`,
            fontWeight: 800,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // fontSize: "0.8rem",
          fontFamily: `${bodyFont}, "Roboto","Helvetica","Arial", sans-serif`,
          textDecoration: "none",
          borderRadius: 0,
          fullWidth: true,
          color: `${ashvilleGrey}`,
          active: {
            color: `${vididWhite}`,
            fontWeight: 800,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // fontFamily: `${bodyFont}`,
          textDecoration: "none",
          fullWidth: true,
          bgcolor: `${justBlack}`,
          borderRadius: 0,
          elevation: 0,
        },
        MuiCard: {
          styleOverrides: {
            root: {
              fullWidth: true,
              color: `${justBlack}`,
              bgcolor: `${justBlack}`,
              borderRadius: 0,
              elevation: 0,
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          // fontFamily: `${bodyFont}`,
          textDecoration: "none",
          fullWidth: true,
        },
        paper: { bgcolor: `${charcoalGrey}` },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          fullWidth: true,
          bgcolor: `${justBlack}`,
        },
      },
    },
  },
});

export default Theme;
