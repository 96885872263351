import "./ui/index.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import LogoAppBar from "./components/LogoAppBar";
import Solutions from "./pages/Solutions";
import Blog from "./pages/Blog";
import About from "./pages/About";
import Theme from "./ui/Theme";
import { CssBaseline, Paper, Toolbar } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import React from "react";
import Home from "./pages/Home";
import BottomNav from "./components/BottomNav";
import styled from "@emotion/styled";
import Footer from "./components/Footer";

// offset the div underneath the AppBar
const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const App = () => {
  const theme = Theme;
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("tablet"));
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <LogoAppBar />
        {isMobileScreen ? (
          <BottomNav />
        ) : (
          <Paper
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              bgcolor: "primary.main",
            }}
            elevation={0}
          >
            <Toolbar />{" "}
          </Paper>
        )}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Home" element={<Home />}></Route>
          <Route path="/Solutions" element={<Solutions />}></Route>
          <Route path="/Blog" element={<Blog />}></Route>
          <Route path="/About" element={<About />}></Route>
        </Routes>
      </BrowserRouter>
      <Offset />
      {isMobileScreen ? null : <Footer />}
    </ThemeProvider>
  );
};

export default App;
