import * as React from "react";
import Image from "mui-image";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Paper,
  Typography,
} from "@mui/material";
import SectionDivider from "../components/SectionDivider";
import styled from "@emotion/styled";

const Home = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        // ml: "12.8rem",
        margin: 0,
        bgcolor: "primary.main",
        boxShadow: 0,

        flex: {
          mobile: "100%",
          tablet: "calc(50% - 20px)",
          desktop: "calc(25% - 20px)",
        },
      }}
    >
      <Card sx={{ bgcolor: "primary.main", boxShadow: 0 }}>
        <CardContent sx={{ boxShadow: 0 }}>
          <Typography
            variant="h1"
            sx={{
              mx: "0.8rem",
              mr: {
                desktop: "50%",
                tablet: "40%",
                mobile: "20%",
              },
              mb: "1.8rem",
              color: "primary.contrast",
              fontSize: {
                mobile: "2.2rem",
                tablet: "4rem",
                desktop: "4.5rem",
              },
            }}
            style={{ wordBreak: "true" }}
          >
            Information Made Simple and Beautiful
          </Typography>
          <CardMedia
            component="img"
            image={require("../assets/images/home.png")}
            sx={{
              height: {
                mobile: "25vh",
                tablet: "40vh",
                desktop: "40vh",
              },
            }}
          />
          <Box sx={{ my: "1rem", display: "flex", flexWrap: "wrap" }} />
          <Typography
            variant="h6"
            sx={{
              mx: "0.8rem",
              ml: {
                desktop: "50%",
                tablet: "40%",
                mobile: "20%",
              },
              color: "primary.contrast",
            }}
            style={{ wordBreak: "true" }}
          >
            We transform your messy data into information that you can easily
            understand, so that you can make decisions with confidence. Our
            cutting-edge cloud-based analytics solutions empower you to
            accelerate business growth and move forward with your vision.
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default Home;
