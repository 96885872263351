import React from "react";
import { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { Link } from "react-router-dom";

const NavTabs = () => {
  const pages = ["Home", "Solutions", "Blog", "About"];
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, tabIndex) => {
    setTabIndex(tabIndex);
  };

  // fix active tab issue
  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      (window.location.pathname === "/Home" && tabIndex !== 0)
    ) {
      setTabIndex(0);
    } else if (window.location.pathname === "/Solutions" && tabIndex !== 1) {
      setTabIndex(1);
    } else if (window.location.pathname === "/Blog" && tabIndex !== 3) {
      setTabIndex(2);
    } else if (window.location.pathname === "/About" && tabIndex !== 4) {
      setTabIndex(3);
    }
  }, [tabIndex]);

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        sx={{
          color: "secondary",
          // borderColor: "primary.contrast",
          // indicatorColor: "primary.contrast",
          // marginLeft: "auto",
          "&:hover": {
            color: "primary.contrast",
          },
          // "& button:active": {
          //   textColor: "primary.contrast",
          // },
        }}
        TabIndicatorProps={{
          sx: {
            bgcolor: "primary.contrast",
            color: "primary.contrast",
          },
        }}
        inkBarStyle={{ color: "primary.contrast" }}
      >
        {pages.map((page) => (
          <Tab
            label={page}
            to={`/${page.replace(" ", "_")}`}
            component={Link}
          />
        ))}
        {/* <Tab label="Home" to="/" />
      <Tab label="Solutions" to="/Solutions" />
      <Tab label="Impact Cases" to="/Impact_Cases" />
      <Tab label="About" to="/About" /> */}
      </Tabs>
    </Box>
  );
};

export default NavTabs;
