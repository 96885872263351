import {
  Typography,
  Paper,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Box,
} from "@mui/material";
import React from "react";
import SectionDivider from "../components/SectionDivider";

const Blog = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        margin: 0,
        bgcolor: "primary.main",
        boxShadow: 0,

        flex: {
          mobile: "100%",
          tablet: "calc(50% - 20px)",
          desktop: "calc(25% - 20px)",
        },
      }}
    >
      <Card sx={{ bgcolor: "primary.main", boxShadow: 0 }}>
        <CardContent sx={{ boxShadow: 0 }}>
          <Typography
            variant="h1"
            sx={{
              mx: "0.8rem",
              mr: {
                desktop: "50%",
                tablet: "40%",
                mobile: "20%",
              },
              mb: "1.8rem",
              color: "primary.contrast",
              fontSize: {
                mobile: "2.2rem",
                tablet: "4rem",
                desktop: "4.5rem",
              },
            }}
            style={{ wordBreak: "true" }}
          >
            Blog
          </Typography>
          <CardMedia
            component="img"
            image={require("../assets/images/blog.png")}
            sx={{
              height: {
                mobile: "25vh",
                tablet: "40vh",
                desktop: "40vh",
              },
            }}
          />
          <Box sx={{ my: "1rem", display: "flex", flexWrap: "wrap" }} />
          <Typography
            variant="h6"
            sx={{
              mx: "0.8rem",
              ml: {
                desktop: "50%",
                tablet: "40%",
                mobile: "20%",
              },
              color: "primary.contrast",
            }}
            style={{ wordBreak: "true" }}
          >
            We believe in being open and sharing knowledge and findings in the
            Open Source development community.
          </Typography>
          <Box sx={{ my: "1.8rem", display: "flex", flexWrap: "wrap" }} />
          <SectionDivider />
          <Typography
            variant="h4"
            sx={{
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            We post our learnings in Front-end and Back-end development here
            regularly.
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default Blog;
