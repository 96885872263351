import React from "react";
import { useState, useEffect } from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { Paper, Toolbar, Box } from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BookIcon from "@mui/icons-material/Book";
import InfoIcon from "@mui/icons-material/Info";
import ContactButton from "./ContactButton";

// const zip = (a, b) => a.map((x, i) => [x, b[i]]);
const pages = ["Home", "Solutions", "Blog", "About"];
const icons = [<HomeIcon />, <DashboardIcon />, <BookIcon />, <InfoIcon />];

const BottomNav = () => {
  // const [value, setValue] = useState("recents");
  // console.log(value);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);

  //   console.log(newValue);
  // };
  const [tabIndex, setTabIndex] = useState("recents");
  const handleTabChange = (event, tabIndex) => {
    setTabIndex(tabIndex);
  };

  // fix active tab issue
  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      (window.location.pathname === "/Home" && tabIndex !== "Home")
    ) {
      setTabIndex("Home");
    } else if (
      window.location.pathname === "/Solutions" &&
      tabIndex !== "Solutions"
    ) {
      setTabIndex("Solutions");
    } else if (window.location.pathname === "/Blog" && tabIndex !== "Blog") {
      setTabIndex("Blog");
    } else if (window.location.pathname === "/About" && tabIndex !== "About") {
      setTabIndex("About");
    }
  }, [tabIndex]);

  return (
    <Paper
      elevation={12}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        bgcolor: "primary.main",
      }}
    >
      <Toolbar>
        <BottomNavigation
          showLabels
          sx={{
            mb: "0.2rem",
            width: "100%",
            bgcolor: "primary.main",
            "& .Mui-selected": {
              "& .MuiBottomNavigationAction-label": {
                color: "primary.contrast",
                fontWeight: 600,
              },
              "& .MuiSvgIcon-root": { color: "primary.contrast" },
            },
          }}
          value={tabIndex}
          onChange={handleTabChange}
        >
          {pages.map((page, index) => (
            <BottomNavigationAction
              label={page}
              value={page}
              component={Link}
              to={`/${page}`}
              icon={icons[index]}
            />
          ))}
        </BottomNavigation>
      </Toolbar>
    </Paper>
  );
};

export default BottomNav;
