import React from "react";
import { AppBar, Box, Toolbar, CssBaseline, Tabs, Tab } from "@mui/material";
import TextLogo from "./TextLogo";
// import GetInTouch from "./GetInTouch";
// import NavTabs from "./NavTabs";
import ImgLogo from "./ImgLogo";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";
import Theme from "../ui/Theme";
import NavTabs from "./NavTabs";
import ContactButton from "./ContactButton";

// offset the div underneath the AppBar
const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const LogoAppBar = () => {
  const theme = Theme;
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("tablet"));

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={1}
        sx={{
          margin: 0,
          padding: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        {/* The Toolbar component places the buttons horizontally */}
        <Toolbar
          disableGutters
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Box sx={{ ml: "1.8rem" }} />
          {/* <GetInTouch /> */}
          <TextLogo />
          <Box sx={{ marginLeft: "auto" }} />
          {isMobileScreen ? null : <NavTabs />}
          {isMobileScreen ? null : (
            <Box
              sx={{
                mx: "1rem",
              }}
            />
          )}

          <ContactButton />
          <Box sx={{ mr: "1.8rem" }} />
        </Toolbar>
      </AppBar>
      <Offset />
    </React.Fragment>
  );
};

export default LogoAppBar;
