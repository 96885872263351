import * as React from "react";
import Image from "mui-image";
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Paper,
  Typography,
} from "@mui/material";
import SectionDivider from "../components/SectionDivider";
import styled from "@emotion/styled";

const About = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        my: "0.8rem",
        margin: 0,
        bgcolor: "primary.main",
        boxShadow: 0,

        flex: {
          mobile: "100%",
          tablet: "calc(50% - 20px)",
          desktop: "calc(25% - 20px)",
        },
      }}
    >
      <Card sx={{ bgcolor: "primary.main", boxShadow: 0 }}>
        <CardContent sx={{ boxShadow: 0 }}>
          <Typography
            variant="h1"
            sx={{
              mx: "0.8rem",
              mr: {
                desktop: "50%",
                tablet: "40%",
                mobile: "20%",
              },
              mb: "1.8rem",
              color: "primary.contrast",
              fontSize: {
                mobile: "2.2rem",
                tablet: "4rem",
                desktop: "4.5rem",
              },
            }}
            style={{ wordBreak: "true" }}
          >
            Why We Are Here
          </Typography>
          <CardMedia
            component="img"
            image={require("../assets/images/about.png")}
            sx={{
              height: {
                mobile: "25vh",
                tablet: "40vh",
                desktop: "40vh",
              },
            }}
          />
          <Box sx={{ my: "1rem", display: "flex", flexWrap: "wrap" }} />
          <Typography
            variant="h6"
            sx={{
              mx: "0.8rem",
              ml: {
                desktop: "50%",
                tablet: "40%",
                mobile: "20%",
              },
              color: "primary.contrast",
            }}
            style={{ wordBreak: "true" }}
          >
            We create value by complimenting your existing systems, not
            replacing them.
          </Typography>
          <Box sx={{ my: "1.8rem", display: "flex", flexWrap: "wrap" }} />
          <SectionDivider />
          <Typography
            variant="h4"
            sx={{
              my: "0.5rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            With our data and analytics solutions, you can solve tough problems
            without the need to invest in costly data infrastructure, hire a
            dedicated data science team, or spend months on extensive data
            processing and cleaning.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              my: "1.8rem",
              ml: {
                desktop: "5%",
                tablet: "5%",
                mobile: "3%",
              },
            }}
          >
            Open Source technologies are more powerful and accessible than ever
            before. Artificial intelligence is making astonishing breakthroughs
            everyday. However, when we examine the systems used by major global
            players, we find that they are still inflexible and unable to
            effectively address the complex challenges many companies face
            today.
            <br />
            <br />
            As we observe, highly skilled analysts are constantly overwhelmed by
            excessive manual processes and generic reporting tasks, which stifle
            their creativity and ability to solve problems efficiently. Senior
            managers are frequently required to rely on their intuition, either
            due to a lack of data integration or information overload, leading
            to suboptimal execution of their strategies. Furthermore,
            organisations are commonly faced with the choice of sacrificing
            either functionality or usability when implementing new systems and
            tools, a compromise that is not subtainable in today's business
            landscape.
            <br />
            <br />
            There is a clear need for custom-built applications that are
            capable, flexible, and cost-effective in tackling the toughest
            problems. That's why we founded empiricus analytica - a company
            dedicated to building just that.
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default About;
