import React from "react";
import { Divider } from "@mui/material";

const SectionDivider = () => {
  return (
    <Divider
      variant="middle"
      sx={{
        bgcolor: "text.secondary",
        // ml: "12.8rem",
        mx: "0.8rem",
        my: "1.8rem",
      }}
    />
  );
};

export default SectionDivider;
